import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Divider, Drawer, Select } from "antd";
import { format } from "date-fns";
import { PlusOutlined } from "@ant-design/icons";
import FormComponent from "../../Components/Form/FormComponent";
import { GET_ALL_TASKS } from "../../Apis/server";
import { useSelector } from "react-redux";
import Loader from "../../Components/Loader";
import axios from "axios";
import Todo from "../../Components/Tasks/ToDo/Todo";
import InProgress from "../../Components/Tasks/InProgress/InProgress";
import Done from "../../Components/Tasks/Done/Done";
import Errorpage from "../../Components/Errorpage";

function Home() {
  const collapsed = useSelector((state) => state.collapsed.collapsed);
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("to-do");
  const [open, setOpen] = useState(false);
  const token = useSelector((state) => state.user.user.token);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [data, setData] = useState();
  const [IsLoading, setIsLoading] = useState(false);
  const [IsError, setIsError] = useState();

  const handleTabChange = (value) => {
    setActiveTab(value);
  };
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const handlePrevDay = () => {
    setSelectedDate(
      (prevDate) => new Date(prevDate.getTime() - 24 * 60 * 60 * 1000)
    );
  };
  const handleNextDay = () => {
    setSelectedDate(
      (prevDate) => new Date(prevDate.getTime() + 24 * 60 * 60 * 1000)
    );
  };
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setIsError(false);
      try {
        const response = await axios.post(
          GET_ALL_TASKS,
          {
            date: format(selectedDate, "yyyy-MM-dd"),
          }, // Use params for GET request
          {
            headers: {
              Authorization: `Bearer ${token}`, // تأكد من استخدام backticks
            },
          }
          // Correctly structured headers
        );

        setData(response.data);
      } catch (error) {
        setIsError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [selectedDate]); // سيعمل كلما تغير selectedDate

  if (IsLoading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  if (IsError) {
    return <Errorpage title={IsError.message} />;
  }

  return (
    <div className="m-4">
      {/* Head home */}
      <div
        className={`flex justify-between fixed top-16 left-0 right-0 z-20  
      ${!collapsed ? "ml-[80px]" : " ml-[250px]"} 
      max-[800px]:ml-0 
      shadow-sm
  bg-slate-100 dark:bg-[#23283d]
   p-4`}
      >
        <h1 className="text-xl font-medium dark:text-gray-200 ">
          {t("dashboard.title")}
        </h1>
        <div className=" flex gap-4">
          <div className="flex gap-6 max-[800px]:hidden">
            <Button shape="circle" onClick={handlePrevDay} title="prev">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 19.5 8.25 12l7.5-7.5"
                />
              </svg>
            </Button>
            <p>{format(selectedDate, "yyyy/MM/dd")}</p>
            <Button shape="circle" onClick={handleNextDay} title="next">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m8.25 4.5 7.5 7.5-7.5 7.5"
                />
              </svg>
            </Button>
          </div>

          <Button
            type="primary"
            onClick={showDrawer}
            icon={<PlusOutlined />}
            title="Add New Task"
          >
            {t("dashboard.New_task")}
          </Button>
          <Drawer
            title={t("dashboard.Add_Tasks")}
            width={900}
            onClose={onClose}
            open={open}
          >
            <FormComponent showDrawer={showDrawer} />
          </Drawer>
        </div>
      </div>
      <Divider />
      {/* Tasks home */}
      <div className="mt-28">
        <div className="grid overflow-hidden sm:grid-cols-2 lg:grid-cols-3 [&>*]:relative [&>*]:p-6 [&>*]:before:absolute [&>*]:before:bg-neutral-100 [&>*]:before:[block-size:100vh] [&>*]:before:[inline-size:1px] [&>*]:before:[inset-block-start:0] [&>*]:before:[inset-inline-start:-1px] [&>*]:after:absolute [&>*]:after:bg-neutral-100 [&>*]:after:[block-size:1px] [&>*]:after:[inline-size:100vw] [&>*]:after:[inset-block-start:-1px] [&>*]:after:[inset-inline-start:0] md:[&>*]:p-4   max-[800px]:hidden">
          <div className="   rounded-md ">
            <Todo to_do_tasks={data?.tasks.to_do_tasks} />
          </div>
          <div className=" rounded-md  ">
            <InProgress in_progress_tasks={data?.tasks.in_progress_tasks} />
          </div>
          <div className=" rounded-md  ">
            <Done done_Tasks={data?.tasks.done_tasks} />
          </div>
        </div>
        <div className="flex w-full flex-col  items-start min-[800px]:hidden">
          <div className="m-2   w-full  flex justify-between items-center gap-4  ">
            <Select
              value={activeTab}
              size="large"
              className="w-40 rounded-lg"
              onChange={handleTabChange}
            >
              <Select.Option value="to-do">{t("Tasks.inReview")}</Select.Option>
              <Select.Option value="progress">
                {t("Tasks.inProgress")}
              </Select.Option>
              <Select.Option value="done">{t("Tasks.Done")}</Select.Option>
            </Select>
            <div className="flex gap-4 min-[800px]:hidden">
              <Button shape="circle" onClick={handlePrevDay} title="prev">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 19.5 8.25 12l7.5-7.5"
                  />
                </svg>
              </Button>
              <p>{format(selectedDate, "yyyy/MM/dd")}</p>
              <Button shape="circle" onClick={handleNextDay} title="next">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m8.25 4.5 7.5 7.5-7.5 7.5"
                  />
                </svg>
              </Button>
            </div>
          </div>
          <div className="mt-10 w-full">
            {activeTab === "to-do" && (
              <div className="w-full">
                <Todo to_do_tasks={data?.tasks.to_do_tasks} />
              </div>
            )}
            {activeTab === "progress" && (
              <div>
                <InProgress in_progress_tasks={data?.tasks.in_progress_tasks} />
              </div>
            )}
            {activeTab === "done" && (
              <div>
                <Done done_Tasks={data?.tasks.done_tasks} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
