import React, { useRef, useState } from "react";
import { CopyOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Input,
  Modal,
  Table,
  Form,
  Image,
  Dropdown,
  Menu,
  Space,
  message,
  Popconfirm,
  Drawer,
  Spin,
} from "antd";

import { LoadingOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {
  MdAccountBox,
  MdDeleteForever,
  MdDeleteOutline,
  MdEdit,
  MdMoreHoriz,
  MdWhatsapp,
} from "react-icons/md";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  ADD_EMPLOYEE,
  DELETE_EMPLOYEE,
  DELETE_FROM_ALL_TASKS,
  EDIT_EMPLOYEE,
} from "../Apis/server";
import axios from "axios";
import { useSelector } from "react-redux";

const TableComponent = ({ data }) => {
  const [employees, setEmployees] = useState([...data?.all_employees]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [ModalAuth, setModalAuth] = useState(false);
  const [Auth, setAuth] = useState();
  const [currentEmployee, setCurrentEmployee] = useState(null);
  const [loading, SetLoading] = useState(false);

  const [editingRow, setEditingRow] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [messageApi, contextHolder] = message.useMessage();
  const token = useSelector((state) => state.user.user.token);
  const [form] = Form.useForm();
  const { t } = useTranslation();

  //filter data
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex, name) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`${t("buttons.Search")} ${name}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {t("buttons.search")}
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            {t("buttons.Reset")}
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            {t("buttons.Filter")}
          </Button>
          <Button type="link" size="small" onClick={() => close()}>
            {t("buttons.Close")}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      const fullName = record[dataIndex[0]]
        ? record[dataIndex[0]][dataIndex[1]]
        : "";
      return fullName.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => {
      const fullName = text ? text.toString() : "";
      return searchedColumn !== dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]} // تأكد من أن هذا صحيح
          autoEscape
          textToHighlight={fullName} // تأكد من أن النص هنا هو النص الكامل
        />
      ) : (
        fullName
      );
    },
  });

  const showAuthModal = (record) => {
    setAuth(record);
    setModalAuth(true);
  };

  //columns
  const columns = [
    {
      key: "image",
      render: (_, record) => (
        <Image
          src={record.information_personal.image_url}
          alt="User Avatar"
          style={{
            width: 35,
            height: 35,
            borderRadius: "50%",
            objectFit: "cover",
          }} // الأسلوب لتنسيق الصورة
        />
      ),
    },
    {
      title: t("Employee.Name"),
      dataIndex: ["information_personal", "full_name"],
      key: "full_name",
      ...getColumnSearchProps(
        ["information_personal", "full_name"],
        t("Employee.Name")
      ),
    },

    {
      title: t("Employee.Phone"),
      dataIndex: ["information_personal", "phone_number"],
      key: "phone",
      width: "20%",
      ...getColumnSearchProps(
        ["information_personal", "phone_number"],
        t("Employee.Phone")
      ),
      responsive: ["md"],
    },
    {
      title: t("Employee.Address"),
      dataIndex: ["information_personal", "address"],
      key: "address",
      responsive: ["md"],
    },
    {
      key: "action",
      width: "10%",

      render: (_, record) => (
        <Dropdown
          overlay={
            <>
              <Menu style={{ padding: "8px" }}>
                <Menu.Item
                  key="view"
                  style={{ padding: "8px" }}
                  onClick={() => showAuthModal(record)}
                  className="m-2"
                >
                  <MdAccountBox className="text-[#65B355] mx-2" />
                  <span> {t("Employee.auth")}</span>
                </Menu.Item>
                <Menu.Item
                  style={{ padding: "8px" }}
                  key="edit"
                  onClick={() =>
                    handleEdit(record.id, record.information_personal)
                  }
                  className="m-2"
                >
                  <MdEdit className="text-primary mx-2" />
                  <span> {t("Employee.edit")}</span>
                </Menu.Item>
                <Menu.Item
                  style={{ padding: "8px" }}
                  key="delete from all tasks"
                  className="m-2"
                >
                  <Popconfirm
                    title={
                      <p className="text-[16px] font-medium ">
                        {t("message.deletefromalltasks")}
                      </p>
                    }
                    description={
                      <p className="text-[14px] font-medium ">
                        {t("message.deletefromalltasks_message")}
                      </p>
                    }
                    key={"all"}
                    okText={t("buttons.delete")}
                    cancelText={t("buttons.cancele")}
                    onConfirm={() => handelDeleteEmployeefromAlltasks(record)}
                    className="flex items-center gap-1"
                  >
                    <MdDeleteForever className="text-[#E12E2E] mx-2" />
                    <span> {t("Employee.deletefromalltasks")}</span>
                  </Popconfirm>
                </Menu.Item>
                <Menu.Item
                  style={{ padding: "8px" }}
                  key="delete"
                  className="m-2"
                >
                  <Popconfirm
                    title={
                      <p className="text-[16px] font-medium ">
                        {t("message.delete_empployee")}
                      </p>
                    }
                    description={
                      <p className="text-[14px] font-medium ">
                        {t("message.delete_empployee_message")}
                      </p>
                    }
                    okText={t("buttons.delete")}
                    cancelText={t("buttons.cancele")}
                    onConfirm={() => handelDeleteEmployee(record)}
                    className="flex items-center gap-1"
                  >
                    <MdDeleteOutline className="text-[#E12E2E] mx-2" />
                    <span> {t("message.delete_empployee")}</span>
                  </Popconfirm>
                </Menu.Item>
              </Menu>
            </>
          }
          trigger={["click"]}
        >
          <Button shape="circle" type="" icon={<MdMoreHoriz />} />
        </Dropdown>
      ),
    },
  ];

  const handleAdd = () => {
    form.resetFields();
    setIsModalVisible(true);
  };

  const handleEdit = (id, information_personal) => {
    setEditingRow(id);
    form.setFieldsValue({
      id: id,
      name: information_personal.full_name,
      phone: information_personal.phone_number,
      address: information_personal.address,
    });
    setIsModalVisible(true);
  };

  const SaveEmployee = async (values) => {
    const url = editingRow ? EDIT_EMPLOYEE : ADD_EMPLOYEE;
    var requestOptions = editingRow
      ? {
          body: {
            full_name: values?.name,
            address: values?.address,
            phone_number: values?.phone,
            employee_id: values?.id,
          },
        }
      : {
          body: {
            full_name: values?.name,
            address: values?.address,
            phone_number: values?.phone,
          },
        };
    try {
      SetLoading(true);
      const response = await axios.post(
        url,
        { ...requestOptions.body },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setCurrentEmployee(null);
      setIsModalVisible(false);

      // showAuthModal(record.information_account);
      if (response?.data.status === true) {
        message.success(response.data.msg, 2.5);
        SetLoading(false);

        setIsModalVisible(false);
        if (editingRow === null) {
          showAuthModal(response.data.employee);
        }
        window.location.reload();
      } else {
        messageApi.open({
          type: "error",
          content: response.data.msg,
        });
        SetLoading(false);
      }
    } catch (error) {
      SetLoading(false);
      if (error.response) {
        // Handle specific network errors based on status code
        const statusCode = error.response.status;
        if (statusCode === 401) {
          message.error(t("error.401"), 2.5);
        } else if (statusCode >= 400 && statusCode < 600) {
          message.error(t("error.404"), 2.5);
        } else {
          message.error(t("error.ather"), 2.5);
        }
      } else {
        message.error(t("error.network"), 2.5);
      }
      console.error("Error submitting the form:", error);
    }
  };
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: SaveEmployee,
    onSuccess: () => {
      queryClient.invalidateQueries(["Recommendation_messages"]);
    },
  });
  const handleSave = () => {
    form.validateFields().then(async (values) => {
      mutation.mutate(values);
    });
  };

  const handleCopy = () => {
    const textToCopy = `Username: ${Auth.information_account.user_name}\nPassword: ${Auth.information_account.password}`;
    navigator.clipboard.writeText(textToCopy).then(() => {
      message.success(t("Employee.copied")); // Optional: display a success message
    });
  };
  console.log(Auth);
  const handleShare = () => {
    const username = Auth.information_account.user_name;
    const password = Auth.information_account.password;

    const messageToShare = `Hi ${Auth.information_personal.full_name},

Deze melding bevat uw inloggegevens voor de nieuwe takenbeheer-app.

Gebruikersnaam: ${username}
Wachtwoord: ${password}

We hopen dat dit helpt!

Met vriendelijke groet,

Furat Groep
www.furatgroup.nl
  `;

    const whatsappURL = `https://api.whatsapp.com/send/?text=${encodeURIComponent(
      messageToShare
    )}`;
    window.open(whatsappURL, "_blank");
    console.log(whatsappURL);
  };
  const handelDeleteEmployeefromAlltasks = async (record) => {
    var requestOptions = {
      body: {
        employee_id: record.id,
      },
    };
    try {
      const response = await axios.post(
        DELETE_FROM_ALL_TASKS,
        { ...requestOptions.body },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response?.data.status === true) {
        message.success(response.data.msg, 2.5);
        window.location.reload();
      } else {
        messageApi.open({
          type: "error",
          content: response.data.msg,
        });
      }
    } catch (error) {
      SetLoading(false);
      if (error.response) {
        // Handle specific network errors based on status code
        const statusCode = error.response.status;
        if (statusCode === 401) {
          message.error(t("error.401"), 2.5);
        } else if (statusCode >= 400 && statusCode < 600) {
          message.error(t("error.404"), 2.5);
        } else {
          message.error(t("error.ather"), 2.5);
        }
      } else {
        message.error(t("error.network"), 2.5);
      }
      console.error("Error submitting the form:", error);
    }
  };
  const handelDeleteEmployee = async (record) => {
    var requestOptions = {
      body: {
        employee_id: record.id,
      },
    };
    try {
      const response = await axios.post(
        DELETE_EMPLOYEE,
        { ...requestOptions.body },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response?.data.status === true) {
        message.success(response.data.msg, 2.5);
        window.location.reload();
      } else {
        messageApi.open({
          type: "error",
          content: response.data.msg,
        });
      }
    } catch (error) {
      SetLoading(false);
      if (error.response) {
        // Handle specific network errors based on status code
        const statusCode = error.response.status;
        if (statusCode === 401) {
          message.error(t("error.401"), 2.5);
        } else if (statusCode >= 400 && statusCode < 600) {
          message.error(t("error.404"), 2.5);
        } else {
          message.error(t("error.ather"), 2.5);
        }
      } else {
        message.error(t("error.network"), 2.5);
      }
      console.error("Error submitting the form:", error);
    }
  };
  return (
    <div className="mt-0 rounded-lg shadow-sm  max-[800px]:p-2">
      {contextHolder}

      <Table
        dataSource={employees}
        columns={columns}
        size="middle"
        rowKey="id"
        title={() => (
          <>
            <div className="flex items-center m-2 justify-between">
              <h1 className="font-medium text-gray-800 dark:text-gray-100">
                {t("Employee.header")}
              </h1>
              <Button type="primary" onClick={() => handleAdd()}>
                {t("Employee.Add")}
              </Button>
            </div>
          </>
        )}
        pagination={{
          size: "default",
          position: ["bottomCenter"],
          pageSize: 10,
        }}
      />

      <Drawer
        width={500}
        title={editingRow ? t("Employee.edit") : t("Employee.Add")}
        open={isModalVisible}
        onClose={() => {
          setIsModalVisible(false);
          setEditingRow(null);
        }}
      >
        <div className="mb-8">
          <p className="text-sm font-normal text-gray-400">
            {t("Employee.desc_form")}
          </p>
        </div>
        <Form
          form={form}
          style={{ width: "80%", margin: " 15px auto" }}
          layout="vertical"
        >
          <Form.Item name="id" label="ID" hidden>
            <Input />
          </Form.Item>
          <Form.Item
            name="name"
            label={t("Employee.Name")}
            rules={[{ required: true, message: t("Employee.place_Name") }]}
          >
            <Input placeholder={t("Employee.palce_Name")} />
          </Form.Item>
          <Form.Item
            name="phone"
            label={t("Employee.Phone")}
            rules={[{ required: true, message: t("Employee.palce_Phone") }]}
          >
            <Input placeholder={t("Employee.palce_Phone")} />
          </Form.Item>
          <Form.Item name="address" label={t("Employee.Address")}>
            <Input placeholder={t("Employee.palce_Address")} />
          </Form.Item>
          <Form.Item className="flex w-full  justify-between gap-4">
            <Button
              onClick={() => {
                setIsModalVisible(false);
                setEditingRow(null);
              }}
              type="default"
            >
              {t("Employee.cancel")}
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              onClick={handleSave}
              className="mx-3"
            >
              {loading ? (
                <>
                  <Spin
                    indicator={<LoadingOutlined spin />}
                    size="small"
                    style={{
                      fontSize: 16,
                      color: "#fff",
                    }}
                  />
                </>
              ) : editingRow ? (
                t("Employee.edit")
              ) : (
                t("Employee.Add")
              )}
            </Button>
          </Form.Item>
        </Form>
      </Drawer>

      <Modal
        open={ModalAuth}
        footer={null}
        onCancel={() => {
          setModalAuth(false);
        }}
      >
        <div className="mb-8">
          <p className="text-lg font-light text-gray-500">
            {t("Employee.send")}
          </p>
          <div className="flex flex-col items-center m-auto">
            <div className=" m-2 w-full">
              <p className="m-1 text-gray-700">{t("Employee.user_name")}:</p>
              <input
                value={Auth?.information_account.user_name}
                className="p-2 border w-full"
              />
            </div>
            <div className=" m-2  w-full">
              <p className="m-1 text-gray-700">{t("Employee.Password")}:</p>
              <input
                value={Auth?.information_account.password}
                className="p-2 border w-full"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-center gap-2">
          <Button onClick={handleCopy} type="text">
            {t("Employee.copy")} <CopyOutlined />
          </Button>
          <Button
            onClick={handleShare}
            type="primary"
            className="mx-2 bg-green-500"
          >
            {t("Employee.share")} <MdWhatsapp />
          </Button>
        </div>
      </Modal>
    </div>
  );
};
export default TableComponent;
