import { Avatar, Badge, Button, Dropdown, Menu } from "antd";
import React from "react";
import { BiUser } from "react-icons/bi";
import UserMenu from "./usermenu";
import { Link } from "react-router-dom";
import {
  MdClose,
  MdDashboard,
  MdMenu,
  MdNotifications,
  MdSettings,
} from "react-icons/md";
import NotificationMenu from "./notificationMenu";
import { GET_ALL_NOTIFICATIONS } from "../Apis/server";
import { useGetData } from "./Hooks/fetchData";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
function NavBar() {
  const [open, setOpen] = React.useState(true);
  const user = useSelector((state) => state.user.user);
  const { isLoading, isError, error, data } = useGetData({
    Url: GET_ALL_NOTIFICATIONS,
    key: "Notification",
    header: {
      Authorization: `Bearer ${user.token}`, // Correct the quote and remove the extra brace
    },
  });
  const { t } = useTranslation();

  const items = [
    {
      key: "1",
      icon: <MdDashboard />,
      label: <Link to={"/"} className="" title="home furat app" />,
    },
    {
      key: "2",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z"
          />
        </svg>
      ),
      label: <Link to={"/employees"} title="Stuffs furat app" />,
    },
    {
      key: "3",
      icon: <MdSettings />,
      label: <Link to="/setting" title="Settings furat app" />,
    },
  ];
  return (
    <>
      <div className="p-1 ">
        <div className="flex gap-6 items-center max-[800px]:hidden ">
          <Dropdown
            overlay={
              <NotificationMenu
                isLoading={isLoading}
                isError={isError}
                error={error}
                data={data}
                token={user.token}
              />
            }
            trigger={["click"]}
          >
            <Badge count={data?.notifications.count_not_read} size="small">
              <Button
                shape="circle"
                icon={<MdNotifications />}
                size="large"
                type="default"
                title={t("notification.notification")}
              />
            </Badge>
          </Dropdown>
          <Dropdown overlay={UserMenu()} trigger={["click"]}>
            <Avatar
              icon={<BiUser />}
              size="large"
              src={user.image_url}
              alt="user-image"
            />
          </Dropdown>
        </div>
        <div className="flex items-center gap-4 min-[800px]:hidden">
          <Dropdown
            overlay={
              <NotificationMenu
                isLoading={isLoading}
                isError={isError}
                error={error}
                data={data}
                token={user.token}
              />
            }
            trigger={["click"]}
          >
            <Badge count={data?.notifications.count_not_read} size="small">
              <Button
                shape="circle"
                icon={<MdNotifications />}
                size="large"
                type="default"
                title={t("notification.notification")}
              />
            </Badge>
          </Dropdown>
          <Link
            className=""
            onClick={() => {
              setOpen(!open);
            }}
            title={`${open ? "Open" : "Close"}`}
          >
            {open ? <MdMenu /> : <MdClose />}
          </Link>
        </div>

        <div
          className={`relative ${
            open ? `hidden` : `block`
          } animate-fade-down  shadow-lg rounded-2xl min-[800px]:hidden  `}
        >
          <Menu
            mode="inline"
            defaultSelectedKeys={["1"]}
            items={items}
            className="absolute z-50 top-20 left-0 w-full  shadow-lg "
          />
        </div>
      </div>
    </>
  );
}

export default NavBar;
